@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Poppins", sans-serif;
}
html {
    font-size: 62.5%; /* it sets base font size to 10px */
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

::-webkit-calendar-picker-indicator {
    background-color: #ff8a00;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 0.3rem;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.1rem;
    text-overflow: "";
}
