.modal-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0 3rem;
    z-index: 1;
    overflow: auto;
}

.modal {
    background-color: #fff;
    box-shadow: 0 3.6rem 3.6rem 0 rgba(0, 0, 0, 0.18),
        0 1.8rem 3.6rem 0 rgba(0, 0, 0, 0.12);
    transition: all 0.5s;
    height: max-content;
    max-width: 140rem;
    margin: 4rem auto;
    padding: 6rem 3rem;
    border-radius: 1.6rem;
}

@media (min-width: 650px) {
    .modal {
        padding: 6rem;
        margin: 6rem auto;
    }
    .modal-overlay {
        padding: 0 6rem;
    }
}

.modal-cancel {
    background-color: #fff;
    cursor: pointer;
    width: 8rem;
    height: 8rem;
    margin: 4rem auto;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}
