.cart {
    height: 5rem;
    width: 5rem;
    background-color: #ecf0f1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart:hover {
    cursor: pointer;
}
