.btn {
    border: none;
    cursor: pointer;
}

.btn-sm {
    height: 5rem;
    border-radius: 13.3rem;
    padding-left: 3.6rem;
    padding-right: 3.6rem;
}

.btn-md {
    height: 5.5rem;
    font-size: 1.6rem;
    font-weight: 600;
    border-radius: 16.2rem;
    padding-right: 3.6rem;
    padding-left: 3.6rem;
}

.btn-lg {
    height: 7.4rem;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 16.2rem;
    padding-right: 5.5rem;
    padding-left: 5.5rem;
}

.btn-xl {
    font-size: 2rem;
    font-weight: 600;
    border-radius: 1rem;
    padding: 1.6rem 3rem;
}

.btn-full {
    width: 100%;
}

.btn-half {
    width: 50%;
}

@media (min-width: 1920px) {
    .btn-xl {
        padding: 2rem 6rem;
    }
}

.btn-green {
    color: #ffffff;
    background-color: #3fa72f;
}

.btn-orange {
    color: #ffffff;
    background-color: #ff8a00;
}

.btn-orange:disabled {
    color: #ffffff;
    background-color: #ffd4a3;
    cursor: not-allowed;
    opacity: 0.6;
}

.btn-black {
    color: #ffffff;
    background-color: #311f09;
}

.btn-gray {
    color: #311f09;
    background-color: #ecf0f1;
}

.btn-white {
    color: #311f09;
    background-color: #ffffff;
}
