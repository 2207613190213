.plus_btn {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    border: none;
    border-radius: 50%;
    background-color: #ff8a00;
}

.vertical_stick {
    height: 1.5rem;
    width: 0.2rem;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.horizontal_stick {
    height: 0.2rem;
    width: 1.5rem;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
